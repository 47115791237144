import { useEffect, useState } from "react";
import { useUserData } from "./use-user-data";
import { UserError } from "../provider/user-provider";
import { Ticket } from "../models/ticket";
import { TokenService } from "../data-source/token-service";

export enum Tabs {
  myTickets = "My Tickets",
  ticketDetails = "Details",
}

export enum ActiveTicketType {
  claim,
  transfer,
}

export type ActiveTicket = {
  ticketId: string;
  type: ActiveTicketType;
};

export type UpcomingTicket = Ticket & { quantity: number };

const useMyTicketData = () => {
  const [currentTab, setCurrentTab] = useState(Tabs.myTickets);
  const [activeTicketId, setActiveTicketId] = useState<
    ActiveTicket | undefined
  >();
  const [upcomingTickets, setUpcomingTickets] = useState<UpcomingTicket[]>([]);

  const {
    getUserTicketInfo,
    isLoading,
    user,
    isConnected,
    error,
    logout,
    setGlabalError,
  } = useUserData();

  const generateUpcomingTickets = () => {
    const upcomingTicketsLocal: UpcomingTicket[] =
      user?.tickets.reduce((accumulator, ticket) => {
        const matchingTicket = accumulator.find(
          (e) =>
            e.tier === ticket.tier &&
            e.eventInformation.eventDatetime ===
              ticket.eventInformation.eventDatetime &&
            e.status === ticket.status
        );

        if (!matchingTicket) {
          accumulator.push({ ...ticket, quantity: 1 });
        } else {
          matchingTicket.quantity += 1;
        }

        return accumulator;
      }, [] as UpcomingTicket[]) ?? [];

    setUpcomingTickets(upcomingTicketsLocal || []);
  };

  useEffect(() => {
    const token = TokenService.getLocalAccessToken();
    if (!token) {
      setGlabalError({
        title: "Login Error",
        message: "There was an unexpected error when loggin in.",
        errorType: UserError.login,
      });
      logout();
    } else if (!!user) {
      getUserTicketInfo();
    }
  }, []);

  useEffect(() => {
    if (user) {
      generateUpcomingTickets();
    }
  }, [user]);

  const onTabChange = (tab: Tabs) => {
    setCurrentTab(tab);
  };

  const onClaimClick = (activeTicket: ActiveTicket) => {
    setActiveTicketId(activeTicket);
  };

  const onTransferClick = (activeTicket: ActiveTicket) => {
    setActiveTicketId(activeTicket);
  };

  const hideModal = () => {
    setActiveTicketId(undefined);
  };

  return {
    currentTab,
    user,
    isLoading: isLoading,
    hasError: error?.errorType === UserError.getUserInfo,
    isConnected,
    activeTicketId,
    upcomingTickets,
    onTabChange,
    getUserTicketInfo,
    onClaimClick,
    onTransferClick,
    hideModal,
  };
};

export { useMyTicketData };
