import CookieConsent from "react-cookie-consent";
import { cookieName } from "../../consts/consts";

const RwCookieConsent = () => {
  return (
    <CookieConsent
      enableDeclineButton
      cookieName={cookieName}
      declineButtonText="Reject"
      buttonText="Accept"
      style={{
        background: "#FFFFFF",
        color: "#000000",
        fontFamily: "Helvetica Neue LT Pro",
      }}
      buttonStyle={{
        color: "#FFFFFF",
        backgroundColor: "#000000",
        fontSize: "14px",
        fontFamily: "Proxima Nova",
        padding: "12px 26px",
        borderRadius: "22px",
      }}
      declineButtonStyle={{
        color: "#000000",
        backgroundColor: "#FFFFFF",
        border: "1px solid #000000",
        fontSize: "14px",
        fontFamily: "Proxima Nova",
        padding: "10px 26px",
        borderRadius: "22px",
        marginRight: 0,
      }}
    >
      We use cookies to help ensure that our website and services are able to
      function properly. These cookies are necessary and so are set
      automatically. View our policy{" "}
      <a
        href={"./rw_cookie_policy.pdf"}
        style={{ textDecoration: "underline", color: "black" }}
        target="_blank"
      >
        here
      </a>
    </CookieConsent>
  );
};
export { RwCookieConsent };
