import { UpcomingTicket } from "../../../hooks/use-my-ticket-data";
import { EmptyTicketView } from "../empty-ticket-view";
import { MyTicketSection } from "../my-ticket-section";

type Props = {
  upcomingTickets: UpcomingTicket[];
  pastTickets: UpcomingTicket[];
};

const MyTicketsView = ({ upcomingTickets, pastTickets }: Props) => {
  const hasNoUpcomingTickets = upcomingTickets.length === 0;
  const hasNoPastTickets = pastTickets.length === 0;

  if (hasNoUpcomingTickets && hasNoPastTickets) {
    return <EmptyTicketView />;
  }
  return (
    <>
      <div className="firstSectionWrapper">
        <MyTicketSection title={"Upcoming Events"} tickets={upcomingTickets} />
      </div>

      {!hasNoPastTickets && (
        <MyTicketSection title={"Past Events"} tickets={pastTickets} />
      )}
    </>
  );
};

export { MyTicketsView };
