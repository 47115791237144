import { ReactNode } from "react";
import "./item_card.css";

type Props = {
  title: String;
  description: String;
  price: number;
  itemDescriptors: string[];
  buyButton: ReactNode;
};

const ItemCard = ({
  title,
  description,
  price,
  itemDescriptors,
  buyButton,
}: Props) => {
  return (
    <div
      className="ticketItem text-center"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="ticket-type-container">
        <p className="p3a">Ticket type</p>
      </div>
      <div className="price-content">
        <p className="p1a">{title}</p>
        <div className="price-description-container">
          <p className="p2a">{description}</p>
          <div className="num d-flex justify-content-center position-relative">
            <span>{`${price}`}</span>
            <p>usd</p>
          </div>
        </div>
      </div>
      <div className="line" />
      <div className="price-content border-container">
        <p className="p3">PACKAGE INCLUDES:</p>
        <div className="list">
          {itemDescriptors.map((itemDescriptor, index) => {
            return (
              <div key={`item-descriptor-${index}`} className="listItem">
                <span>{`0${index + 1}`}</span>
                <span>{itemDescriptor}</span>
              </div>
            );
          })}
        </div>
        {buyButton}
        <a href={"./rw_terms_and_conditions.pdf"} target="_blank">
          Terms and conditions apply.
        </a>
      </div>
    </div>
  );
};

export { ItemCard };
