import "./page-not-found.css";
import RwB1 from "../../assets/images/rw_bl.png";

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="row">
        <img src={RwB1}></img>
        <h1>404</h1>
      </div>
      <h2>Page not found</h2>
      <p>The requested url does not exist</p>
    </div>
  );
};

export { PageNotFound };
