import { RemoteDataSource } from "../data-source/remote-data-source";
import { RemoteTicket } from "../data-source/types/get-owner-response";
import { ErrorHandler } from "../error-handling/error-handler";
import { ApiError } from "../models/api_error";
import { Order } from "../models/order";
import { Result } from "../models/result";
import { Ticket } from "../models/ticket";
import { User } from "../models/user";
import {
  generateDateFromTimestamp,
  mapTicketStatusResponse,
  mapTicketTierResponse,
  mapTicketTransactionStatus,
} from "./mapping-helpers";

class RemoteDataRepository {
  static loginUser = async (): Promise<Result<void, ApiError>> => {
    try {
      const response = await RemoteDataSource.loginUser();
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      return ErrorHandler.handle<void>(error);
    }
  };

  static getUserTicketInfo = async (): Promise<Result<User, ApiError>> => {
    try {
      const response = await RemoteDataSource.getUserTicketinfo();
      if (response.status === 200) {
        const responseData = response.data;
        return {
          ok: true,
          value: {
            id: responseData.owner_uid,
            walletAddress: responseData.wallet_address,
            email: responseData.email,
            externalId: responseData.external_id,
            tickets:
              responseData.tickets?.map(
                (data: RemoteTicket): Ticket => ({
                  id: data.ticket_uid,
                  eventTitle: "Robbie Williams",
                  eventLocation: "Lightcycle",
                  eventInformation: {
                    eventDatetime: generateDateFromTimestamp(
                      data.event_information.event_datetime
                    ),
                    eventbriteId: data.event_information.eventbrite_id,
                    eventId: data.event_information.event_uid,
                  },
                  status: mapTicketStatusResponse(data.status),
                  tier: mapTicketTierResponse(data.tier),
                  orderId: data.order_uid,
                  mintingInformation: {
                    transactionStatus: mapTicketTransactionStatus(
                      data.minting_information.transaction_status
                    ),
                    tokenId: data.minting_information.token_id,
                    transactionHash: data.minting_information.transaction_hash,
                  },
                })
              ) ?? [],
          },
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      return ErrorHandler.handle<User>(error);
    }
  };

  static createOrder = async (
    orderId: string
  ): Promise<Result<Order, ApiError>> => {
    try {
      const response = await RemoteDataSource.createOrder(orderId);
      if (response.status === 200 || response.status === 201) {
        const responseData = response.data;
        return {
          ok: true,
          value: {
            id: responseData.uid,
            eventbriteOrderId: responseData.eventbrite_order_id,
            orderStatus: responseData.order_status,
            userId: responseData.user_uid,
            eventId: responseData.event_uid,
            eventbriteEmail: responseData.eventbrite_email,
          },
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      return ErrorHandler.handle<Order>(error);
    }
  };

  static transferTicket = async (
    ticketId: string,
    emailTo: string
  ): Promise<Result<void, ApiError>> => {
    try {
      const response = await RemoteDataSource.transferTicket(ticketId, emailTo);
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      return ErrorHandler.handle<void>(error);
    }
  };

  static claimTicket = async (
    ticketId: string
  ): Promise<Result<void, ApiError>> => {
    try {
      const response = await RemoteDataSource.claimTicket(ticketId);
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      return ErrorHandler.handle<void>(error);
    }
  };
}

export { RemoteDataRepository };
