import { Accordion } from "react-bootstrap";
import "./faq-section.css";

const FaqSection = () => {
  return (
    <div className="faq-section">
      <div className="container">
        <p className="p1 col-8" data-aos="fade-up" data-aos-duration="1000">
          FREQUERENTLY ASKED QUESTIONS
        </p>

        <Accordion
          defaultActiveKey="0"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header className="accordion-header">
              Who is Robbie Williams?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              Robbie Williams is a multi platinum selling artist with 30 years
              experience. You can find out more about Robbie at{" "}
              <a href="https://robbiewilliams.com" target="_blank">
                https://robbiewilliams.com
              </a>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="1"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              What is LightCycle?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              LightCycle is a realistic virtual commercial city, created using
              Unreal Engine 5 and real-time edge rendering. With a powerful
              editor and AI generation technology, both users and merchants can
              effortlessly bring their content to life, offering a seamless and
              secure experience powered by blockchain technology.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="2"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              How do I purchase a ticket?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              To purchase a ticket, go to{" "}
              <a href="https://robbiewilliams.lightcycle.city" target="_blank">
                https://robbiewilliams.lightcycle.city
              </a>
              , scroll down to the “Choose Your Ticket” section and click “Buy
              Ticket” and complete the payment journey.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="3"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              How do I view my ticket?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              To view your ticket, go to{" "}
              <a href="https://robbiewilliams.lightcycle.city" target="_blank">
                https://robbiewilliams.lightcycle.city
              </a>{" "}
              and click on the profile icon next to the “Book Tickets” button.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="4"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              How do I transfer a ticket to a friend?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              In your profile dashboard, go to the “Details & ticket transfer”
              section, click on transfer and enter the email address you would
              like to transfer the ticket to.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="5"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              Where do I access the concert?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              You will be sent a link to the experience closer to the date of
              your selected show.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="6"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              How do I contact support?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              For technical issues with the experience you can contact us by
              joining our{" "}
              <a href="https://discord.com/invite/pdtsD2wMx5" target="_blank">
                discord
              </a>{" "}
              and sending a message in the *
              <a
                href="https://discord.com/channels/978247434708844575/1206570195821535273"
                target="_blank"
              >
                RWLC-Support
              </a>
              * channel. <br />
              <br />
              For business related issues contact info@lightcycle.city
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="7"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              What does my ticket give me?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              Entry ticket to the 3+ hour virtual music experience in LightCycle
              <br />
              1. including: Avatar performance and exclusive concert screening.
              <br />
              2. Chance to win exclusive prizes.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="8"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              How is my data used?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              Please view our{" "}
              <a href={"./rw_privacy_policy.pdf"} target="_blank">
                privacy policy
              </a>{" "}
              to learn more about how we use your data.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="9"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              Date and time of the event?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              The date and time will be in your email confirmation and will be
              based on the date and time you chose when purchasing your ticket.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="10"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              How can I claim my rewards?{" "}
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              The project team will be in touch after the event to contact those
              who have placed in the Top 10 on the Fan Leaderboard about how to
              claim prizes.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="11"
            style={{ borderBottom: "1px solid white" }}
          >
            <Accordion.Header className="accordion-header">
              Is there an age restriction on who can buy tickets?
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              Anyone under 16 must have a parent or guardian (18 or over)
              present whilst engaging with the experience.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export { FaqSection };
