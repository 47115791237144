import LocationIcon from "../../../assets/images/location_icon.png";
import TicketIcon from "../../../assets/images/arcticons_mtickets.png";
import "./ticket-listing.css";
import { UpcomingTicket } from "../../../hooks/use-my-ticket-data";

type Props = {
  ticket: UpcomingTicket;
  isFirstChild: boolean;
};

const TicketListing = ({ ticket, isFirstChild }: Props) => {
  const tdStyle = isFirstChild
    ? { paddingTop: "18px" }
    : { paddingTop: "12px" };

  return (
    <tr key={ticket.id} className="ticket-listing-row">
      <td scope="row" style={tdStyle}>
        {ticket.eventInformation.eventDatetime}
      </td>
      <td style={tdStyle}>{ticket.eventTitle}</td>
      <td style={tdStyle}>
        <img className="icon" src={LocationIcon} alt="" />
        {ticket.eventLocation}
      </td>
      <td style={tdStyle}>
        <img className="icon" src={TicketIcon} alt="" />
        {ticket.quantity}
      </td>
      <td style={tdStyle}>{ticket.tier}</td>
      <td style={tdStyle}>{ticket.status}</td>
    </tr>
  );
};

export { TicketListing };
