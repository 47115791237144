import "./footer-section.css";
import FooterLogo from "../../../assets/images/footLogo.png";
import { lightcycleUrl } from "../../../consts/consts";

const FooterSection = () => {
  return (
    <div className="footer-section">
      <div className="container">
        <div className="row d-flex justify-content-between footContent mb-5">
          <div className="footLink">
            <a href={"./rw_privacy_policy.pdf"} target="_blank">
              Privacy policy
            </a>
            <a href={"./rw_terms_and_conditions.pdf"} target="_blank">
              Terms and conditions
            </a>
          </div>

          <p className="vertex-labs">
            ©2023 Vertex Labs / LightCycle. All Rights Reserved
          </p>

          <a href={lightcycleUrl} target="_blank" className="footLogo">
            <img src={FooterLogo} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export { FooterSection };
