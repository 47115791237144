import env from "react-dotenv";
import { useUserData } from "../../../hooks/use-user-data";
import { ItemCard } from "./item_card/item_card";

import "./ticket_section.css";
import { EVENT_MODAL_ID } from "../../../consts/consts";

const TicketSection = () => {
  const { login, isConnected } = useUserData();

  const buyButton = isConnected ? (
    <button
      id={EVENT_MODAL_ID}
      className="button d-flex justify-content-center align-items-center"
    >
      BUY TICKET
    </button>
  ) : (
    <button
      onClick={() => login({ routeToTicketsOnSuccess: false })}
      className="button d-flex justify-content-center align-items-center"
    >
      BUY TICKET
    </button>
  );
  return (
    <div className="ticket-section">
      <div className="container">
        <p className="p1" data-aos="fade-up" data-aos-duration="800">
          CLAIM YOUR TICKET. WIN PRIZES!
        </p>
        <p className="p2" data-aos="fade-up" data-aos-duration="1000">
          Your ticket unlocks the chance to win amazing prizes.
        </p>
        <div className="column" data-aos="fade-up" data-aos-duration="1200">
          <p className="p3  col-md-6">
            If you collect enough fan points to place in the Top 10 of the
            Robbie Williams Fan Leaderboard you will win big.
          </p>
          <p className="p4  col-md-6">
            <strong>1st</strong> : VIP video call with Robbie Williams.
            <br />
            <br />
            <strong>2nd</strong> : Tickets to a Robbie Williams concert of your
            choice.
            <br />
            <br />
            <strong>3rd</strong> : Exclusive signed XXV CD & Vinyl Collection
            <br />
            <br />
            <strong>4th - 10th</strong> : Official Robbie Williams jumper
          </p>
        </div>
        <p className="p5" data-aos="fade-up" data-aos-duration="800">
          CHOOSE YOUR TICKET
        </p>
      </div>
      <div className="ticket">
        <div className="ticket-inner-container">
          <div className="row d-flex justify-content-between w-100 ticket-container">
            <ItemCard
              key={"item-card-1"}
              title={"GENERAL ADMISSION"}
              description={"Secure your ticket to an unmissable experience."}
              price={25}
              itemDescriptors={[
                "Entry ticket to the 3+ hour virtual music experience in Lightcycle including:",
                "Avatar performance and exclusive concert screening.",
                "Chance to win exclusive prizes.",
              ]}
              buyButton={buyButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { TicketSection };
